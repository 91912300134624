// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { AuthnModule, EventType } from '@imprivata-cloud/authn';
import { tracer } from '../../tracing';
import { WEB_APP_NAME } from '../../shared/constants';
import { useBootstrap } from '../../hooks';

export const MfaFlow: React.FC = () => {
  const {
    tenantId,
    readyForAuthenticating,
    username,
    onSuccess,
    contextResource,
    userId,
    oidcRequestData,
    factors,
  } = useBootstrap(new URLSearchParams(window.location.search));

  return readyForAuthenticating ? (
    <AuthnModule
      contextResource={contextResource}
      tenantId={tenantId}
      onEvent={({ event, data }) => {
        console.log('[AUTHN MODULE EVENT]', event);

        if (event === EventType.AUTHENTICATED) {
          onSuccess(data?.oidcAuthorizationCode || '');
        }
      }}
      factors={factors}
      requestConfig={{
        clientName: WEB_APP_NAME,
        oidcRequestData,
        username,
        userId,
      }}
      tracing={{
        workflowId: tracer.getTraceContext().workflowId || '',
      }}
    />
  ) : null;
};
