// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useTranslation } from 'react-i18next';
import { QueryParam } from './constants';
import { IcpFlow } from './containers/icp-flow/IcpFlow';
import { MfaFlow } from './containers/mfa-flow/MfaFlow';
import { AppBackground } from './components/AppBackground';

export const App: React.FC = () => {
  const { t } = useTranslation();

  const isFaceFactor =
    new URLSearchParams(window.location.search)
      .get(QueryParam.AcrValues)
      ?.includes('face') || false;

  return isFaceFactor ? (
    <IcpFlow />
  ) : (
    <AppBackground title={t('layout.header')}>
      <MfaFlow />
    </AppBackground>
  );
};
