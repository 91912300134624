// Copyright 2022, Imprivata, Inc.  All rights reserved.

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { ImprHeaders } from '../shared/types';

const securityHeaders = {
  [ImprHeaders.StrictTransportSecurity]: 'max-age=86400;includeSubDomains',
  [ImprHeaders.XSSProtection]: '1;mode=block',
  [ImprHeaders.XFrameOptions]: 'DENY',
  [ImprHeaders.XContentTypeOptions]: 'nosniff',
};

export const client = axios.create({
  withCredentials: true,
  maxRedirects: 0,
  headers: {
    ...securityHeaders,
  },
});

// axios instances can be created from other instances,
// but the type definitions are missing `create` method on `AxiosInstance` type
// here is a github issue for this:
// https://github.com/axios/axios/issues/5095
//
// eslint-disable-next-line
// @ts-ignore
export const retryOn401Client: typeof client = client.create();

axiosRetry(retryOn401Client, {
  retries: 1,
  retryCondition: error => error.response?.status === 401,
});
