// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { useEffect, useState } from 'react';
import {
  continueWithAuthzCode,
  continueWithIdtoken,
  setDefaultHeader,
  startIdpAuthn,
} from './api/api';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import { FactorOption } from '@imprivata-cloud/authn';
import { WEB_APP_NAME } from './shared/constants';
import { ImprHeaders, NextAction, OidcRequestData } from './shared/types';
import { getIdToken, waitForExtensionApi } from './browser-extension-api';
import { QueryParam, availableFactorTypes } from './constants';

const sendNotAjaxPostRequest = (
  url: string,
  params: Record<string, string>,
) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = url;

  for (const [key, value] of Object.entries(params)) {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = key;
    hiddenField.value = value;

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);
  form.submit();
};

export type UseBootstrapReturnValue = {
  tenantId: string;
  readyForAuthenticating: boolean;
  username: string;
  onSuccess: (oidcAuthzCode: string) => void;
  contextResource: {
    resourceType: string;
    contextType: string;
  };
  userId: string;
  oidcRequestData: OidcRequestData | undefined;
  factors: FactorOption[];
};

export const useBootstrap = (
  queryParams: URLSearchParams,
): UseBootstrapReturnValue => {
  const isFaceFactor =
    queryParams.get(QueryParam.AcrValues)?.includes('face') || false;
  const loginHint = queryParams.get(QueryParam.LoginHint);

  const [readyForAuthenticating, setReadyForAuthenticating] =
    useState(isFaceFactor);
  const [factors, setFactors] = useState<FactorOption[]>(
    isFaceFactor ? [{ factorType: 'face' } as FactorOption] : [],
  );

  const [username, setUsername] = useState(loginHint || '');
  const [userId, setUserId] = useState('');
  const [oidcRequestData, setOidcRequestData] = useState<
    OidcRequestData | undefined
  >(undefined);

  const tenantId = queryParams.get(QueryParam.TenantId) || '';
  const spRequestId = queryParams.get(QueryParam.SpRequestId) || '';
  const contextResource = {
    resourceType: queryParams.get(QueryParam.ResourceType) || '',
    contextType: queryParams.get(QueryParam.ContextType) || '',
  };

  useEffect(() => {
    // this function runs only if isFaceFactor is false
    const start = async () => {
      setDefaultHeader(
        ImprHeaders.ImprClientName,
        jsonToBase64({
          name: WEB_APP_NAME,
        }),
      );

      setDefaultHeader(ImprHeaders.ImprTenantId, tenantId);

      let { authnData, idpResponseData } = await startIdpAuthn({
        spRequestId,
        availableFactorTypes,
      });

      if (authnData?.nextAction === NextAction.GET_IDENTITY_TOKEN) {
        const idToken = await getIdToken();
        const continueWithIdtokenResponse = await continueWithIdtoken(idToken, {
          spRequestId,
          availableFactorTypes,
        });
        authnData = continueWithIdtokenResponse.authnData;
        idpResponseData = continueWithIdtokenResponse.idpResponseData;
      }

      if (idpResponseData?.aadAuthnUrl) {
        sendNotAjaxPostRequest(
          idpResponseData.aadAuthnUrl,
          idpResponseData.aadAuthnAttributes,
        );
      }

      if (authnData?.startAuthnData) {
        setFactors(authnData.startAuthnData.nextFactors);
        // TODO: should it conflict with the username received in startIdpAuthn?
        setUsername(loginHint || authnData.startAuthnData.username);
        setUserId(authnData.startAuthnData.userId);
        setOidcRequestData(authnData.startAuthnData.oidcRequestData);
      }

      setReadyForAuthenticating(true);
    };

    // run only if it's not for face id
    if (!isFaceFactor) {
      waitForExtensionApi(start);
    }
  }, []);

  const onSuccess = async (oidcAuthzCode: string) => {
    const { idpResponseData } = await continueWithAuthzCode({
      spRequestId,
      oidcAuthzCode,
    });

    if (idpResponseData?.aadAuthnUrl) {
      sendNotAjaxPostRequest(
        idpResponseData.aadAuthnUrl,
        idpResponseData.aadAuthnAttributes,
      );
    }
  };

  return {
    tenantId,
    readyForAuthenticating,
    username,
    onSuccess,
    contextResource,
    userId,
    oidcRequestData,
    factors,
  };
};
