// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { jsonToBase64, stringToBase64 } from '@imprivata-cloud/data-privacy-js';

export const stringToBase64Url = (str: string): string => {
  return base64toBase64Url(stringToBase64(str));
};

export const jsonToBase64Url = (obj: Record<string, unknown>): string => {
  return base64toBase64Url(jsonToBase64(obj));
};

// base64Url is a variant of base64 that is URL and filename safe.
// All it does differently is omitting padding '=' and replacing '+' -> '-' and '/' -> '_'.
export const base64toBase64Url = (str: string): string => {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};
