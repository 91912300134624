// Copyright 2024, Imprivata, Inc.  All rights reserved.

import { Tracer, TracingSolutionType } from '@imprivata-cloud/common';
import getConfig from '../appConfigUtils';
export * from './constants';

const {
  ZIPKIN_TRACING_URL,
  OTLP_TRACING_URL,
  COLLECT_TRACING,
  TRACING_SOLUTION_TYPE,
} = getConfig();

const queryParams = new URLSearchParams(window.location.search);
const journeyId = queryParams.get('workflowId') || '';
const tracingSolutionType =
  TracingSolutionType[
    TRACING_SOLUTION_TYPE as keyof typeof TracingSolutionType
  ];

export const tracer = new Tracer('idp-web-ui', journeyId, {
  zipkinExportUrl: ZIPKIN_TRACING_URL,
  otlpExportUrl: OTLP_TRACING_URL,
  collectSpans: COLLECT_TRACING === 'true',
  ...(tracingSolutionType !== undefined ? [tracingSolutionType] : []),
});
